// /*    Print Style*/
@media print {
  * {
    background: #fff !important;
    color: #000 !important;

    box-shadow: none !important;
    text-shadow: none !important;

    &::before, &::after {
      background: #fff !important;
      color: #000 !important; } }

  a {
    text-decoration: underline;

    &:visited {
      text-decoration: underline; }

    &[href]::after {
      content: " (" attr(href) ")"; } }

  abbr[title]::after {
    content: " (" attr(title) ")"; }

  a {
    &[href^="#"]::after, &[href^="javascript:"]::after {
      content: ""; } }

  pre {
    white-space: pre-wrap !important;
    border: 1px solid #999;
    page-break-inside: avoid; }

  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }

  img {
    page-break-inside: avoid; }

  p, h2, h3 {
    orphans: 3;
    widows: 3; }

  h2, h3, h4, h5 {
    page-break-after: avoid; }

  #up, #top, #toggle, .menu, svg, .vh, nav, .slider, .go-top, .map, [href="impressum.html"] {
    display: none; } }

/*    Ende Print Style    */
